<template>
    <div class="p-16 shadow" style="background-color:#F8F8F8">
        <p class="text-3xl mb-10 leading-normal">Wenn Sie Fragen zur Funktionalität der Website haben, können Sie sie unten stellen! Sie werden Ihre Antwort kurz nach dem Absenden erhalten.</p>
        <form v-if="!loggedUser.admin" @submit.prevent="submitForm">
            <div class="form-control">
                <label>Deine E-Mail:</label>
                <input type="text" v-model="email" autocomplete="off">
            </div>
            <div class="border-2 relative cursor-pointer border-grey-400">
                <editor
                    apiKey="8spqxq12m8z598gogaydst2muefa6ygx32kigzgt05bazbbp"
                    placeholder="Hier schreiben"
                    @input="updateContent($event)"
                    :init="{
                    height: 324,
                    menubar: false,
                    branding: false,
                    toolbar_location: 'bottom',
                    resize: true,
                    skin: 'naked',
                    ref: 'editori',
                    statusbar: false,
                    plugins: [
                    'advlist autolink lists link preview anchor',
                    'code fullscreen',
                    'paste code',
                    ],
                    toolbar:
                    'undo redo formatselect link bold italic backcolor forecolor \
                        alignleft aligncenter alignright alignjustify \
                        bullist numlist outdent indent \
                        fullscreen',
                    }"
                >
                </editor>
            </div>
            <p class="text-2xl mt-10 text-red-400" v-if="error">{{ error }}</p>
            <button type="submit" class="mt-10 primary-btn">Senden</button>
        </form>
        <div v-if="loggedUser.admin" class="mt-20 messages flex flex-wrap">
            <div v-for="(help, idx) in helps" :key="help._id" :class="`flex justify-between flex-col message border-l-4 ${!help.answered ? 'border-red-500' : 'border-green-500'} pl-10`">
                <button @click="removeHelp(help._id)" type="button" :class="`focus:outline-none close ${!help.answered ? 'hidden' : 'inline-block'}`"><i class="fas fa-times text-3xl text-red-500"></i></button>
                <div class="info">
                    <h2 class="font-bold text-3xl mb-10">{{ help.email }}</h2>
                    <div class="text-2xl" v-html="help.content"></div>
                </div>
                
                <button class="text-3xl text-blue-500 self-end focus:outline-none" @click="showReply(idx)">Reply <i class="ml-5 mt-10 fas fa-reply"></i></button>
                <div class="reply" ref="reply">
                    <textarea class="p-5 border-2 border-grey-400" placeholder="Hier schreiben"></textarea>
                    <button type="button" @click="sendEmail(help.email, idx)" class="text-2xl p-5 bg-blue-500 text-white font-bold focus:outline-none">Senden</button>
                </div>
            </div>
            <div v-if="!helps.length">
                <p class="text-4xl font-bold text-gray-800">Alles ist klar!</p>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .close {
        position: absolute;
        top: 0;
        right: 0;
    }
    .messages {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10rem;
    }
    .message {
        position: relative;
    }
    .reply {
        display: inline-block;
        position: absolute;
        bottom: 3rem;
        right: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all .3s ease;
        button {
            position: absolute;
            bottom: 10px;
            right: 10px;
        }
    }
    .reply.show {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
    .reply {
        textarea {
            font-size: 18px;
            padding: 15px;
            min-width: 40rem;
            min-height: 30rem;
            resize: none;
            &::placeholder {
                font-size: 18px;

            }
        }
    }
</style>

<script>
    import Editor from '@tinymce/tinymce-vue';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        data() {
            return {
                contentValue: '',
                error: '',
                email: ''
            }
        },
        mounted() {
            if(this.loggedUser.admin) {
                this.getAllHelp();
            }
        },
        methods: {
            ...mapActions(['addHelp', 'getAllHelp', 'sendReply', 'deleteHelp']),
            updateContent(value) {
                this.contentValue = value;
            },
            submitForm() {
                if(this.email && this.contentValue) {
                    this.addHelp({
                        email: this.email,
                        content: this.contentValue
                    });
                } else {
                    this.error = 'Daten müssen ausgefüllt werden'
                }
                
            },
            showReply(index) {
                const els = document.querySelectorAll(".reply");
                els.forEach((el, idx) => {
                    if(idx != index) {
                        if(el.classList.contains('show')) {
                            el.classList.remove('show');
                        }
                    }
                });
                els.forEach((el, idx) => {
                    if(idx == index) {
                        if(el.classList.contains("show")) {
                            el.classList.remove('show');
                        } else {
                            el.classList.add('show');
                        }
                    }
                })
                
            },
            sendEmail(email, index) {
                const txtarea = document.querySelectorAll('.reply textarea')[index];
                const content = txtarea.value;
                this.sendReply({email, content});
                const message = document.querySelectorAll('.message')[index];
                const close = document.querySelectorAll('.close')[index];
                message.classList.remove('border-red-500');
                message.classList.add('border-green-500');
                close.classList.remove('hidden');
                close.classList.add('inline-block');
                document.querySelector(".show").classList.remove('show');
                txtarea.value = '';
            },
            removeHelp(index) {
                this.deleteHelp(index);
            }
        },
        computed: {
            ...mapGetters(['loggedUser', 'helps'])
        },
        components: {
            editor: Editor
        },
    }
</script>