var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-16 shadow",staticStyle:{"background-color":"#F8F8F8"}},[_c('p',{staticClass:"text-3xl mb-10 leading-normal"},[_vm._v("Wenn Sie Fragen zur Funktionalität der Website haben, können Sie sie unten stellen! Sie werden Ihre Antwort kurz nach dem Absenden erhalten.")]),(!_vm.loggedUser.admin)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"form-control"},[_c('label',[_vm._v("Deine E-Mail:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"text","autocomplete":"off"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"border-2 relative cursor-pointer border-grey-400"},[_c('editor',{attrs:{"apiKey":"8spqxq12m8z598gogaydst2muefa6ygx32kigzgt05bazbbp","placeholder":"Hier schreiben","init":{
                height: 324,
                menubar: false,
                branding: false,
                toolbar_location: 'bottom',
                resize: true,
                skin: 'naked',
                ref: 'editori',
                statusbar: false,
                plugins: [
                'advlist autolink lists link preview anchor',
                'code fullscreen',
                'paste code',
                ],
                toolbar:
                'undo redo formatselect link bold italic backcolor forecolor \
                    alignleft aligncenter alignright alignjustify \
                    bullist numlist outdent indent \
                    fullscreen',
                }},on:{"input":function($event){return _vm.updateContent($event)}}})],1),(_vm.error)?_c('p',{staticClass:"text-2xl mt-10 text-red-400"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('button',{staticClass:"mt-10 primary-btn",attrs:{"type":"submit"}},[_vm._v("Senden")])]):_vm._e(),(_vm.loggedUser.admin)?_c('div',{staticClass:"mt-20 messages flex flex-wrap"},[_vm._l((_vm.helps),function(help,idx){return _c('div',{key:help._id,class:`flex justify-between flex-col message border-l-4 ${!help.answered ? 'border-red-500' : 'border-green-500'} pl-10`},[_c('button',{class:`focus:outline-none close ${!help.answered ? 'hidden' : 'inline-block'}`,attrs:{"type":"button"},on:{"click":function($event){return _vm.removeHelp(help._id)}}},[_c('i',{staticClass:"fas fa-times text-3xl text-red-500"})]),_c('div',{staticClass:"info"},[_c('h2',{staticClass:"font-bold text-3xl mb-10"},[_vm._v(_vm._s(help.email))]),_c('div',{staticClass:"text-2xl",domProps:{"innerHTML":_vm._s(help.content)}})]),_c('button',{staticClass:"text-3xl text-blue-500 self-end focus:outline-none",on:{"click":function($event){return _vm.showReply(idx)}}},[_vm._v("Reply "),_c('i',{staticClass:"ml-5 mt-10 fas fa-reply"})]),_c('div',{ref:"reply",refInFor:true,staticClass:"reply"},[_c('textarea',{staticClass:"p-5 border-2 border-grey-400",attrs:{"placeholder":"Hier schreiben"}}),_c('button',{staticClass:"text-2xl p-5 bg-blue-500 text-white font-bold focus:outline-none",attrs:{"type":"button"},on:{"click":function($event){return _vm.sendEmail(help.email, idx)}}},[_vm._v("Senden")])])])}),(!_vm.helps.length)?_c('div',[_c('p',{staticClass:"text-4xl font-bold text-gray-800"},[_vm._v("Alles ist klar!")])]):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }